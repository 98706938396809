import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload-file-component"
export default class extends Controller {
  static targets = ['input', 'image', 'video', 'audio', 'preview', 'imageUploadArea', 'removePhoto', 'checkbox'];

  connect() {
    const fileUrl = this.element.dataset.fileUrl;
    if (fileUrl) {
      this.loadPreview(fileUrl);
    }
  }

  preview() {
    const input = this.inputTarget;
    const file = input.files[0];
    const fileSize = file.size / 1024 / 1024;
    const maxFileSize = parseFloat(input.dataset.maxSize);
    const acceptedTypes = input.dataset.acceptedTypes.split(',');
    const locale = input.dataset.locale || 'en';

    if (fileSize > maxFileSize) {
      const message = I18n.t[locale]['js']['upload_file_component']['error_file_too_large'].replace('%{max_size}', maxFileSize);
      this.showError(message, locale);
      input.value = '';
      return;
    }

    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (!acceptedTypes.includes(fileExtension)) {
      const acceptedTypesList = acceptedTypes.join(', ').toUpperCase();
      const message = I18n.t[locale]['js']['upload_file_component']['error_invalid_file_type'].replace('%{accepted_types}', acceptedTypesList);
      this.showError(message, locale);
      input.value = '';
      return;
    }

    const fileType = file.type.split('/')[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      this.clearPreview();
      if (fileType === 'image') {
        this.showImagePreview(e.target.result);
      } else if (fileType === 'video') {
        this.showVideoPreview(e.target.result);
      } else if (fileType === 'audio') {
        this.showAudioPreview(e.target.result);
      }

      this.unmarkCheckbox();
    };

    reader.readAsDataURL(file);
  }

  showError(message, locale) {
    Swal.fire({
      title: I18n.t[locale]['js']['upload_file_component']['error_title'],
      text: message,
      icon: 'error',
      timer: 5000,
      showConfirmButton: false,
      showCloseButton: true
    });
  }

  loadPreview(url) {
    const input = this.inputTarget;
    const fileType = input.accept.split('/')[0];

    this.clearPreview();
    if (fileType === 'image') {
      this.showImagePreview(url);
    } else if (fileType === 'video') {
      this.showVideoPreview(url);
    } else if (fileType === 'audio') {
      this.showAudioPreview(url);
    }
  }

  showImagePreview(url) {
    this.imageTarget.src = url;
    this.imageTarget.style.display = 'block';
    this.previewTarget.classList.remove('hidden');
    this.imageUploadAreaTarget.classList.add('hidden');
  }

  showVideoPreview(url) {
    this.videoTarget.src = url;
    this.videoTarget.style.display = 'block';
    this.previewTarget.classList.remove('hidden');
    this.imageUploadAreaTarget.classList.add('hidden');
  }

  showAudioPreview(url) {
    this.audioTarget.src = url;
    this.audioTarget.style.display = 'block';
    this.previewTarget.classList.remove('hidden');
    this.imageUploadAreaTarget.classList.add('hidden');
  }

  clearPreview() {
    this.imageTarget.style.display = 'none';
    this.videoTarget.style.display = 'none';
    this.audioTarget.style.display = 'none';
    this.imageTarget.src = '';
    this.videoTarget.src = '';
    this.audioTarget.src = '';
  }

  removePhoto(event) {
    event.preventDefault();
    this.inputTarget.value = '';
    this.clearPreview();

    this.previewTarget.classList.add('hidden');
    this.imageUploadAreaTarget.classList.remove('hidden');

    this.markCheckbox();
  }

  markCheckbox() {
    if (this.hasCheckboxTarget) {
      this.checkboxTarget.checked = true;
    }
  }

  unmarkCheckbox() {
    if (this.hasCheckboxTarget) {
      this.checkboxTarget.checked = false;
    }
  }

  dragover(event) {
    event.preventDefault();
    event.currentTarget.classList.add('bg-indigo-100');
  }

  dragleave(event) {
    event.currentTarget.classList.remove('bg-indigo-100');
  }

  drop(event) {
    event.preventDefault();
    event.currentTarget.classList.remove('bg-indigo-100');

    let droppedFiles = event.dataTransfer.files;

    if (droppedFiles.length > 0) {
      this.inputTarget.files = droppedFiles;
      this.preview();
    }
  }
}
