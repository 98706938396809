import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        const supportedLanguages = ["es", "ca", "en", "pt", "fr"];
        const locale = this.element.dataset.locale || "en";
        this.locale = supportedLanguages.includes(locale) ? locale : "en";
        this.initializeBiggerPicture();
        this.initializeVideoJS();
    }

    initializeBiggerPicture() {
        this.bp = BiggerPicture({ target: document.body });
        this.imageLinks = this.element.querySelectorAll("#images > a");

        this.imageLinks.forEach(link => {
            const img = new Image();
            img.src = link.dataset.img;

            img.onload = () => {
                link.dataset.width = img.width;
                link.dataset.height = img.height;
            };

            link.addEventListener("click", (event) => this.openGallery(event));
        });
    }

    openGallery(event) {
        event.preventDefault();
        const link = event.currentTarget;

        this.bp.open({
            items: this.imageLinks,
            el: link,
        });
    }

    initializeVideoJS() {
        const videoElements = document.querySelectorAll("video");
        videoElements.forEach(video => {
            videojs(video, {
                controls: true,
                playbackRates: [0.25, 0.5, 1, 1.25, 1.5, 2],
                fluid: true,
                language: this.locale,
            });
        });
    }

    disconnect() {
        this.bp.close();
    }
}
