import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-all"
export default class extends Controller {
  static targets = ["checkbox", "selectAll"]

  connect() {
    this.updateSelectAllCheckbox()
  }

  toggleAll(event) {
    const isChecked = event.target.checked
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked
    })
  }

  updateSelectAllCheckbox() {
    const total = this.checkboxTargets.length
    const checked = this.checkboxTargets.filter((checkbox) => checkbox.checked).length
    this.selectAllTarget.checked = total === checked
  }

  toggleCheckbox() {
    this.updateSelectAllCheckbox()
  }
}
