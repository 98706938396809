{
  "ca": {
    "js": {
      "checkout": {
        "selected_plan": "Pla seleccionat",
        "selected_period": "Període seleccionat",
        "price": "Preu",
        "monthly": "Mensual",
        "yearly": "Anual",
        "per_month": "/mes",
        "per_year": "/any"
      },
      "copy_link_modal": {
        "confirm_button_aria_label": "Copiar al portapapers",
        "confirm_button_text": "<i class=\"fa fa-clipboard\"></i> Copiar",
        "connected": "Connectat al modal de copiar enllaç",
        "copied_message": "L'enllaç s'ha copiat al porta-retalls.",
        "copied_title": "Copiat!",
        "error_message": "No es va poder copiar el text: ",
        "focus_confirm": "Enfocar confirmar",
        "html": "<b>%{link}</b>",
        "show_close_button": "Mostrar botó de tancar",
        "title": "<strong>Copiar enllaç</strong>"
      },
      "image_upload": {
        "error_invalid_file_type": "Tipus de fitxer no vàlid",
        "error_file_must_be_jpg_or_png": "El fitxer ha de ser una imatge JPG o PNG.",
        "error_file_too_large": "Fitxer massa gran",
        "error_max_size_10mb": "El fitxer ha de ser inferior a 10MB."
      },
      "upload_file_component": {
        "error_title": "Error",
        "error_file_too_large": "El fitxer no ha de superar els %{max_size}MB.",
        "error_invalid_file_type": "Tipus de fitxer no permès. Els tipus acceptats són: %{accepted_types}."
      }
    }
  }
}
