{
  "en": {
    "js": {
      "checkout": {
        "selected_plan": "Selected plan",
        "selected_period": "Selected period",
        "price": "Price",
        "monthly": "Monthly",
        "yearly": "Yearly",
        "per_month": "/month",
        "per_year": "/year"
      },
      "copy_link_modal": {
        "confirm_button_aria_label": "Copy to clipboard",
        "confirm_button_text": "<i class=\"fa fa-clipboard\"></i> Copy",
        "connected": "Connected to copy link modal",
        "copied_message": "The link has been copied to your clipboard.",
        "copied_title": "Copied!",
        "error_message": "Could not copy text: ",
        "focus_confirm": "Focus confirm",
        "html": "<b>%{link}</b>",
        "show_close_button": "Show close button",
        "title": "<strong>Copy link</strong>"
      },
      "image_upload": {
        "error_invalid_file_type": "Invalid file type",
        "error_file_must_be_jpg_or_png": "The file must be a JPG or PNG image.",
        "error_file_too_large": "File too large",
        "error_max_size_10mb": "The file must be less than 10MB."
      },
      "upload_file_component": {
        "error_title": "Error",
        "error_file_too_large": "The file must not exceed %{max_size}MB.",
        "error_invalid_file_type": "Invalid file type. Accepted types are: %{accepted_types}."
      }
    }
  }
}
